import axios from 'axios'
import qs from 'qs'

const service = axios.create({
    //process.env.BASE_API
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: true,
    timeout:30000,
})

export function get(url,params){
    const config={
        //method url是每个请求中必须的，公共的，单参数不是必须的
        method:"get",
        url
    }
    if(params){config.params=params}
    return service(config)
}

service.interceptors.request.use(function (request) {
    // request.headers.token = 'token=11124654654687';
    // console.log(request) // 请求成功
    // 1.获取token
    /*var token = localStorage.getItem('token')
    if (token) {
        // token拼接Bearer空格请求接口权限
        let newToken = token
        // 2.必须在请求头中使用 Authorization 字段提供 token 令牌,请求时头部带上token
        request.headers.Authorization = newToken
    }*/
    return request;
}, function (error) {
    // console.log(error); // 请求失败
    return Promise.reject(error);
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // console.log(response.data.data) // 响应成功
    return response
}, function (error) {
    // console.log(error); // 响应失败
    return Promise.reject(error)
})

export default function Api(url, method, data = {}, istransform = true, extra) {
    let requestObj = {
        url,
        method
    }
    if (extra && typeof extra == 'object') {
        //添加额外请求头(这里是需要加密的)
        requestObj.headers = extra;
    }
    if (method.toLocaleLowerCase() == 'post') {

        if (istransform) {
            requestObj.transformRequest = [function (data) {
                return qs.stringify(data)
            }]
        }
        if (extra && extra == 'blob') {
            //导出额外设置响应类型
            requestObj.responseType = extra;
        }
        requestObj.data = data;
    } else if (method.toLocaleLowerCase() == 'get') {
        requestObj.params = data
    }
    return service(requestObj)
}

